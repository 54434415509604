



















import { get, find, cloneDeep } from 'lodash';
import { Debounce } from 'lodash-decorators';
import {Vue, Component, Watch} from 'vue-property-decorator';
import Title from '@/components/Title.vue';
import ContentBox from "@/components/ContentBox.vue";
import Audience from "@/components/Audience.vue";
import AudienceDetails from '@/components/AudienceDetails.vue';

@Component({
  components: { Title, ContentBox, Audience, AudienceDetails }
})
export default class Audiences extends Vue {

  @Debounce(1000)
  @Watch('audiences', {deep: true})
  private updateFeatureAudiences(updated) {
    const features = get(this, '$store.state.configuration.edited.features', []);
    features.forEach(feature => {
      ['enableFor', 'disableFor'].forEach(key => {
        feature[key]?.forEach((audience, index) => {
          const {id} = audience;
          const match = find(updated, {id});
          if (!match) return feature[key].splice(index, 1);
          ['audienceName', 'rules'].forEach(attribute => audience[attribute] = cloneDeep(match[attribute]));
        })
      })
    });
  }

  get audiences() {
    return get(this, '$store.state.configuration.edited.audiences', []);
  }

  get details() {
    return get(this, '$store.state.configuration.audience');
  }

  create() {
    this.audiences.unshift({ audienceName: 'NewAudience', rules: [], id: `audience-${this.audiences.length}` });
  }

}
