

















import { get } from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Title from "@/components/Title.vue";
import ContentBox from "@/components/ContentBox.vue";

@Component({
  components: { Title, ContentBox }
})
export default class Audience extends Vue {

  @Prop({ type: Object, default: () => ({}) })
  record: Record<any, any>;

  get rules() {
    return get(this, 'record.rules', []).map(rule => rule.ruleName)
  }

  select() {
    this.$store.commit('configuration/setAudience', this.record);
  }

  remove() {
    this.$store.commit('configuration/removeAudience', this.record);
  }
}

